<template>
  <div>
    <i @click="$router.push('/settings/billing')" style="color:#45a2ff" class="close_modal fal fa-times-circle"></i>
    <div class="subscriptions_plan container">
      <div class="main">
        <div class="pricing_page">
          <div class="heading_x">
            <h2>Get 70% OFF On Annual Plans <br> This Black Friday</h2>
            <p class="mt-3">Choose plan that works best for you, feel free to contact us.</p>
          </div>

          <div class="pricing__box">
            <div class="inner mt-5  clear row pricing-table-container">
              <div class="price_box_3 col-md-6" v-for="(item,index) in pricing_list.plan">
                <div class="inner" :class="{'white_bg':index === 0, 'green_bg': index === 1}">
                  <div class="inner_section">
                    <p class="package">{{ item.display.toUpperCase() }}</p>


                    <h2 class="price" :class="item.color">
                      <sup>$</sup>
                      {{ item.price.replace('$', '') }}
                      <br>
                      <!--                      <sup>$</sup>-->
                      <span class="discounted_price">
                         ${{ item.discounted_price.replace('$', '') }}
                      </span>
                      <br>
                      <sub class="year">Per Year (Excl. VAT)</sub>
                    </h2>

                    <p class="duration">{{ item.duration }}</p>

                    <div class="brand">
                      <span class="bold">{{ item.brands }}</span> Brand
                    </div>

                    <ul class="list_detail">
                      <li class="plan-list">
                        <div class="d-inline-block icon">
                          <i class="fal fa-check pricing-table-icon"></i>
                        </div>
                        <span>{{ item.clicks }}</span> clicks per month
                      </li>

                      <li class="plan-list">
                        <div class="d-inline-block icon">
                          <i class="fal fa-check pricing-table-icon"></i>
                        </div>
                        <span>{{ item.campaigns }}</span> Campaigns
                      </li>

                      <li class="plan-list">
                        <div class="d-inline-block icon">
                          <i class="fal fa-check pricing-table-icon"></i>
                        </div>
                        <span>{{ item.pixels }}</span> Retargeting Pixel
                      </li>

                      <li class="plan-list">
                        <div class="d-inline-block icon">
                          <i class="fal fa-check pricing-table-icon"></i>
                        </div>
                        <span>{{ item.links }}</span> {{ getSiteDetails.agency_name }} Links
                      </li>
                      <li class="plan-list">
                        <div class="d-inline-block icon">
                          <i class="fal fa-check pricing-table-icon"></i>
                        </div>
                        <span>QR Codes</span>
                      </li>
                      <li class="plan-list">
                        <div class="d-inline-block icon">
                          <i class="fal fa-check pricing-table-icon"></i>
                        </div>
                        <span>Third Party Integration</span>
                      </li>
                      <template v-if="item.display!=='Replug Basic'">
                        <li class="plan-list">
                          <div class="d-inline-block icon">
                            <i class="fal fa-check pricing-table-icon"></i>
                          </div>
                          <span>{{ item.custom_domains }} Custom Domains</span>
                        </li>
                      </template>
                      <template v-if="item.display!=='Replug Basic'">
                        <li class="plan-list">
                          <div class="d-inline-block icon">
                            <i class="fal fa-check pricing-table-icon"></i>
                          </div>
                          <span>{{ item.bio_links }} Bio-Links</span>
                        </li>
                      </template>
                      <template v-if="item.display!=='Replug Basic'">
                        <li class="plan-list">
                          <div class="d-inline-block icon">
                            <i class="fal fa-check pricing-table-icon"></i>
                          </div>
                          <span>Onboarding & Call Support</span>
                        </li>
                      </template>
                      <template v-if="item.display!=='Replug Basic'">
                        <li class="plan-list">
                          <div class="d-inline-block icon">
                            <i class="fal fa-check pricing-table-icon"></i>
                          </div>
                          <span>A/B Testing</span>
                        </li>
                      </template>
                      <template v-if="item.display!=='Replug Basic'">
                        <li class="plan-list">
                          <div class="d-inline-block icon">
                            <i class="fal fa-check pricing-table-icon"></i>
                          </div>
                          <span>Traffic Routing</span>
                        </li>
                      </template>
                      <template v-if="item.display!=='Replug Basic'">
                        <li class="plan-list">
                          <div class="d-inline-block icon">
                            <i class="fal fa-check pricing-table-icon"></i>
                          </div>
                          <span>Deep Linking</span>
                        </li>
                      </template>

                    </ul>
                    <div v-if="getSubscription.internal_id && getSubscription.internal_id === item.internal_id">
                      <button class="mt-3 btn---cta btn-round  btn-border current_plan_btn pricing_btn">
                        <span>Current Plan</span>
                      </button>
                    </div>
                    <div v-else>
                      <button class="mt-3 upgrade_btn btn---cta btn-round pricing_btn "
                              @click="upgradePlan(item)">
                        <span>Buy Now</span>
                                              <clip-loader v-if="item.loader" style="margin-left:120px;margin-top:-23px"
                                                           :color="'#168eea'"
                                                           :size="'14px'"></clip-loader>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="credit_card_container container mt-5">
        <div class="d-flex flex-row justify-content-around flex-wrap">
          <div class="credit_card_item-1">
            <img src="../../../assets/img/credit-card/card_visa.png" fluid alt="Fluid image"/>
          </div>
          <div class="credit_card_item-2">
            <img src="../../../assets/img/credit-card/card_amex.png" fluid
                 alt="Fluid image"/>
          </div>
          <div class="credit_card_item-3">
            <img src="../../../assets/img/credit-card/card_mastercard.png" fluid
                 alt="Fluid image"/>
          </div>
          <div class="credit_card_item-4">
            <img src="../../../assets/img/credit-card/card_paypal.png" fluid
                 alt="Fluid image"/>
          </div>
        </div>
      </div>
      <div class="container marketers">
        <h5 class="text-center"><strong>30-days</strong> money back guarantee</h5>
        <h2 class="text-center"><strong>10,000+ Marketers Already Trust {{ getSiteDetails.agency_name }}</strong></h2>

        <div class="row flex-row flex-wrap justify-content-around mt-5 brands_section">
          <img class="img-fluid" src="../../../assets/img/new-marketers/Group 17050.png" alt="Fluid image"/>
          <!--          <div class="markerter-item">-->
          <!--          </div>-->
          <img class="img-fluid" src="../../../assets/img/new-marketers/Group 17051.png" alt="Fluid image"/>
          <!--          <div  class="markerter-item">-->
          <!--          </div>-->
          <img class="img-fluid" src="../../../assets/img/new-marketers/Group 17052.png" alt="Fluid image"/>
          <!--          <div class="markerter-item">-->
          <!--          </div>-->
          <img class="img-fluid" src="../../../assets/img/new-marketers/Group 17053.png" alt="Fluid image"/>
          <!--          <div  class="markerter-item">-->
          <!--          </div>-->
          <img class="img-fluid" src="../../../assets/img/new-marketers/Unknown-6.png" alt="Fluid image"/>
          <!--          <div  class="markerter-item">-->
          <!--          </div>-->

          <img class="img-fluid" src="../../../assets/img/new-marketers/ogilvy-vector-logo.png" alt="Fluid image"/>
          <!--          <div class="markerter-item">-->
          <!--          </div>-->
          <img class="img-fluid"
               src="../../../assets/img/new-marketers/logo-mondelez-17206c289ec22aa33c625c7a69f57597.png"/>
          <!--          <div class="markerter-item mt-2">-->
          <!--          </div>-->
          <img class="img-fluid" src="../../../assets/img/new-marketers/logo-wpp-d103e0310bd037ecaeae1d81725cbb05.png"/>
          <!--          <div class="markerter-item mt-2">-->
          <!--          </div>-->
          <img class="img-fluid" src="../../../assets/img/new-marketers/Unknown.png" alt="Fluid image"/>
          <!--          <div class="markerter-item mt-2">-->
          <!--          </div>-->
          <img class="img-fluid" src="../../../assets/img/new-marketers/Unknown-2.png" alt="Fluid image"/>
          <!--          <div class="markerter-item">-->
          <!--          </div>-->

          <img class="img-fluid" src="../../../assets/img/new-marketers/Unknown-4.png" alt="Fluid image"/>
          <!--          <div class="markerter-item">-->
          <!--          </div>-->
          <img class="img-fluid" src="../../../assets/img/new-marketers/Unknown-5.png" alt="Fluid image"/>
          <!--          <div class="markerter-item mt-2">-->
          <!--          </div>-->
          <img class="img-fluid" src="../../../assets/img/new-marketers/Unknown-1.png" alt="Fluid image"/>
          <!--          <div class="markerter-item">-->
          <!--          </div>-->
          <img class="img-fluid bmw_logo" src="../../../assets/img/new-marketers/Unknown-3.png" alt="Fluid image"/>
          <!--          <div class="markerter-item mt-1">-->
          <!--          </div>-->

          <!--          dddddddddddddddddddddddd-->
          <!--          <div class="markerter-item">-->
          <!--            <img src="../assets/img/new-marketers/ogilvy-vector-logo.png" fluid-->
          <!--                 alt="Fluid image"/>-->
          <!--          </div>-->
          <!--          <div class="markerter-item mt-2">-->
          <!--            <img-->
          <!--                 src="../assets/img/new-marketers/logo-mondelez-17206c289ec22aa33c625c7a69f57597.png" fluid-->
          <!--                 alt="Fluid image"/>-->
          <!--          </div>-->
          <!--          <div class="markerter-item mt-2">-->
          <!--            <img src="../assets/img/new-marketers/logo-wpp-d103e0310bd037ecaeae1d81725cbb05.png"-->
          <!--                 fluid alt="Fluid image"/>-->
          <!--          </div>-->
          <!--          <div class="markerter-item mt-2">-->
          <!--            <img src="../assets/img/new-marketers/Unknown.png" fluid alt="Fluid image"/>-->
          <!--          </div>-->
          <!--          <div class="markerter-item">-->
          <!--            <img src="../assets/img/new-marketers/Unknown-2.png" fluid alt="Fluid image"/>-->
          <!--          </div>-->

          <!--          <div class="markerter-item">-->
          <!--            <img src="../assets/img/new-marketers/Unknown-4.png" fluid alt="Fluid image"/>-->
          <!--          </div>-->
          <!--          <div class="markerter-item mt-2">-->
          <!--            <img src="../assets/img/new-marketers/Unknown-5.png" fluid alt="Fluid image"/>-->
          <!--          </div>-->
          <!--          <div class="markerter-item">-->
          <!--            <img src="../assets/img/new-marketers/Unknown-1.png" fluid alt="Fluid image"/>-->
          <!--          </div>-->
          <!--          <div class="markerter-item mt-1">-->
          <!--            <img src="../assets/img/new-marketers/Unknown-3.png" fluid alt="Fluid image"/>-->
          <!--          </div>-->
          <!--          eeeeeeeeeeeeeeeeeeeeeee-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import { blackFridayPricingList } from '@/mixins/pricing_constants'

export default (
  {
    data: function () {
      return {
        pricing_list: blackFridayPricingList
      }
    },
    async created () {

    },
    components: {},
    computed: {
      ...mapGetters(['getProfile', 'getSubscription']),
    },
    methods: {
      ...mapActions(['upgradeSubscription']),

      async upgradePlan (item) {
        item.loader = true
        await this.upgradeSubscription({ 'subscription': item })
        await this.getUserSubscription()
        item.loader = false
      }
    }

  })
</script>

<style scoped lang="less">

.subscriptions_plan {
  padding-left: 0 !important;

  .main {
    position: relative;
    padding: 40px;

    .pricing_page {
      .heading_x {
        h2 {
          margin-top: 10px;
          font-size: 2.3rem !important;
          line-height: 1.3;
          color: #1f216e !important;
          font-weight: 800;
          text-align: center;
        }

        .mt-3 {
          color: #1f216e !important;
          margin-top: 10px;
          font-size: 1rem;
          font-weight: lighter;
          opacity: 0.7;
          text-align: center;
        }
      }

      .pricing__box {
        .toggle_btn_single {
          text-align: center;

          .discount_wrapper {
            display: inline-block;
            position: relative;

            .toggle_btn_inner {
              position: relative;
              display: inline-block;
              //border: solid 1px rgba(143, 144, 182, 0.5);
              overflow: hidden;
              background: #eff6fc;
              //.border-radius(30px);
              padding: 4px;
            }

            .btn_month {
              margin-left: -25px;
              letter-spacing: 1px;

              span {
                font-size: 1rem !important;
              }
            }

            .btn_year {
              float: right;
              margin-right: 1px;
              letter-spacing: 1px;

              span {
                font-size: 1rem !important;
              }
            }

            .active_move {
              background: #f4f4f7;
              padding: 8px 20px;
              min-width: 150px;
              height: ~"calc(100% - 6px)";
              top: 3px;
              position: absolute;
              //.border-radius(30px);
              //.transition_linear;
              background: white;
            }

            .active_move.active_left {
              left: 4px;
            }

            .active_move.active_right {
              left: 145px;
            }

            button {
              position: relative;
              min-width: 96px;
              background: transparent;
              border: none;
              box-shadow: none;
              text-transform: none;

              span {
                font-size: 14px;
                font-weight: bold;
                color: #1f216e;
                //.transition_linear;
              }
            }

            .active {
              // background: @green2;
              span {
                color: #45a2ff;
              }
            }

            .row {
              .discount-text {
                font-size: 0.8rem;
                color: #22c07f;
                text-align: center;
                margin-left: 70px;
              }

              img {
                width: 60px;
                margin-top: -45px;
                height: 80px;
                margin-left: 13px
              }
            }
          }
        }

        .inner {
          padding: 25px 15px;

          .list_detail {
            letter-spacing: 1px;
            margin-top: -35px;
            padding: 15px;
            width: 100%;

            li {
              font-size: 1rem !important;
            }

            .brand-li {
              div {
                background: white;
                padding: 10px;
                width: 410px;
                margin-left: -38px;
                opacity: 0.2;
              }
            }
          }

          .package {
            font-size: 18px;
            font-weight: 800;
            //color: @font_color;
            text-align: center;
            letter-spacing: 2.4px;
            font-size: 1.3rem !important;
          }

          .price {
            font-size: 3rem;
            font-weight: 1000;
            margin: 40px 0;
            //color: @font_color;
            text-align: center;

            sup, sub {
              font-size: 18px;
              font-weight: 600;
              font-style: normal;
            }
          }

          .pricing_btn {
            font-size: 16px;
            font-weight: bold;

          }

          .current_plan_btn {
            border: 1px solid #2dd678;
            cursor: not-allowed;
            background: white;

            span {
              color: #2dd678;
            }

            &:hover {
              background: #2dd678;

              span {
                color: white;
              }
            }
          }
        }
      }
    }

    .btn_block {
      margin-top: 30px;
    }
  }
}

.blur-plan {
  opacity: 0.5;
}

.brand {
  background: #4871f8;
  margin: 0 -15px;
  padding: 10px 0;
  font-weight: bold;
  font-size: 16px;
  margin-top: -10px;
  margin-bottom: 40px;
  text-align: center;
}

.plan-list {
  margin-top: 18px !important;
  font-weight: lighter !important;
  font-size: 1rem !important;
}

.line_strike {
  margin-top: 18px !important;
  font-weight: lighter !important;
  font-size: 1rem !important;

  span {
    opacity: 0.7 !important;
  }
}

.white_bg {
  background-image: url('/assets/img/plans/white.png') !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  border-radius: 10px;

  .inner_section {
    .brand {
      background: #f5f5f5;

      .bold {
        background: #f5f5f5;
      }
    }

    .discounted_price {
      color: #1f216e !important;
      font-weight: 600;
      font-size: 30px;
      text-decoration: line-through;
      text-decoration-color: #f44336;
    }

    .list_detail {
      .plan-list {
        color: #1f216e !important;

        span {
          color: #1f216e !important;
          margin-left: 20px;
        }

        .icon {
          background: #23daa9;
          width: 27px;
          border-radius: 50px;
          height: 26px;

          .fa-check {
            font-weight: normal;
            font-size: large;
            margin-left: 4px;
            color: white !important;
            margin-top: 4px;
          }
        }
      }

      .line_strike {
        span {
          color: rgba(31, 33, 110, 0.5) !important;
          margin-left: 20px;
        }

        .icon {
          background: #f72c5b;
          width: 27px;
          border-radius: 50px;
          height: 26px;

          .fa-times {
            font-weight: normal;
            font-size: large;
            margin-left: 8px;
            color: white !important;
            margin-top: 4px;
          }
        }
      }
    }

    .btn---cta {
      width: 100%;
      padding: 15px;
      background: whitesmoke;

      span {
        color: #0d44fb;
      }
    }
  }
}

.green_bg {
  background-image: url("/assets/img/plans/green.png") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  border-radius: 10px;

  .inner_section {
    color: white !important;

    .package {
      color: white !important;
    }

    .price {
      color: white !important;

      .discounted_price {
        color: white !important;
        font-weight: 600;
        font-size: 30px;
        margin: 25px 0;
        text-decoration: line-through;
        text-decoration-color: #f44336;
      }
    }

    .brand {
      color: white !important;

      span {
        color: white !important;
      }
    }

    .list_detail {
      li {
        color: white !important;

        span {
          color: white !important;
        }
      }
    }

    .brand {
      background: #39dd99;

      .bold {
        background: #39dd99;
      }
    }

    .list_detail {
      .plan-list {
        span {
          margin-left: 20px;
        }

        .icon {
          background: white;
          width: 27px;
          border-radius: 50px;
          height: 26px;

          .fa-check {
            font-weight: normal;
            font-size: large;
            margin-left: 4px;
            color: #23daa9 !important;
            margin-top: 4px;
          }
        }
      }

      .line_strike {
        span {
          margin-left: 20px;
        }

        .icon {
          background: white;
          color: #f72c5b;
          width: 27px;
          border-radius: 50px;
          height: 26px;

          .fa-times {
            font-weight: normal;
            font-size: large;
            margin-left: 7px;
            color: #f72c5b !important;
            margin-top: 4px;
          }
        }
      }
    }

    .btn---cta {
      width: 100%;
      padding: 15px;
      background: whitesmoke;

      span {
        color: #0d44fb;
      }
    }
  }
}

.blue_bg {
  background-image: url("/assets/img/plans/blue.png") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  border-radius: 10px;

  .inner_section {
    color: white !important;

    .package {
      color: white !important;
    }

    .price {
      color: white !important;

      .discounted_price {
        color: white !important;
        font-weight: 600;
        font-size: 30px;
        margin: 25px 0;
        text-decoration: line-through;
        text-decoration-color: #f44336;
      }
    }

    .brand {
      color: white !important;

      span {
        color: white !important;
      }
    }

    .list_detail {
      li {
        color: white !important;

        span {
          color: white !important;
        }
      }
    }

    span {
      margin-left: 20px;
    }

    .brand {
      background: #4871f8;

      .bold {
        background: #4871f8;
      }
    }

    .list_detail {
      .plan-list {
        .icon {
          background: white;
          width: 26px;
          border-radius: 50px;
          height: 26px;

          .fa-check {
            font-weight: normal;
            font-size: large;
            margin-left: 4px;
            color: #23daa9 !important;
            margin-top: 4px;
          }
        }
      }

      .line_strike {
        .icon {
          background: white;
          color: #f72c5b;
          width: 26px;
          border-radius: 50px;
          height: 26px;

          .fa-times {
            font-weight: normal;
            font-size: large;
            margin-left: 5px;
            color: #f72c5b !important;
            margin-top: 4px;
          }
        }
      }
    }

    .btn---cta {
      width: 100%;
      padding: 15px;
      background: whitesmoke;

      span {
        color: #0d44fb;
      }
    }
  }
}

.month {
  font-size: 0.9rem !important;
  opacity: 0.7;
}

.year {
  font-size: 0.9rem !important;
  opacity: 0.7;
}

.brands_section {
  .green_bg {
    background-image: url("/assets/img/plans/green.png") !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;

    .inner_section {
      color: white !important;

      .package {
        color: white !important;
      }

      .price {
        color: white !important;

        .discounted_price {
          color: white !important;
          font-weight: 600;
          font-size: 30px;
          margin: 25px 0;
          text-decoration: line-through;
          text-decoration-color: #f44336;
        }
      }

      .brand {
        color: white !important;

        span {
          color: white !important;
        }
      }

      .list_detail {
        li {
          color: white !important;

          span {
            color: white !important;
          }
        }
      }

      .brand {
        background: #39dd99;

        .bold {
          background: #39dd99;
        }
      }

      .list_detail {
        .plan-list {
          span {
            margin-left: 20px;
          }

          .icon {
            background: white;
            width: 27px;
            border-radius: 50px;
            height: 26px;

            .fa-check {
              font-weight: normal;
              font-size: large;
              margin-left: 4px;
              color: #23daa9 !important;
              margin-top: 4px;
            }
          }
        }

        .line_strike {
          span {
            margin-left: 20px;
          }

          .icon {
            background: white;
            color: #f72c5b;
            width: 27px;
            border-radius: 50px;
            height: 26px;

            .fa-times {
              font-weight: normal;
              font-size: large;
              margin-left: 7px;
              color: #f72c5b !important;
              margin-top: 4px;
            }
          }
        }
      }

      .btn---cta {
        width: 100%;
        padding: 15px;
        background: whitesmoke;

        span {
          color: #0d44fb;
        }
      }
    }
  }

  .blue_bg {
    background-image: url("/assets/img/plans/blue.png") !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;

    .inner_section {
      color: white !important;

      .package {
        color: white !important;
      }

      .price {
        color: white !important;

        .discounted_price {
          color: white !important;
          font-weight: 600;
          font-size: 30px;
          margin: 25px 0;
          text-decoration: line-through;
          text-decoration-color: #f44336;
        }
      }

      .brand {
        color: white !important;

        span {
          color: white !important;
        }
      }

      .list_detail {
        li {
          color: white !important;

          span {
            color: white !important;
          }
        }
      }

      span {
        margin-left: 20px;
      }

      .brand {
        background: #4871f8;

        .bold {
          background: #4871f8;
        }
      }

      .list_detail {
        .plan-list {
          .icon {
            background: white;
            width: 26px;
            border-radius: 50px;
            height: 26px;

            .fa-check {
              font-weight: normal;
              font-size: large;
              margin-left: 4px;
              color: #23daa9 !important;
              margin-top: 4px;
            }
          }
        }

        .line_strike {
          .icon {
            background: white;
            color: #f72c5b;
            width: 26px;
            border-radius: 50px;
            height: 26px;

            .fa-times {
              font-weight: normal;
              font-size: large;
              margin-left: 5px;
              color: #f72c5b !important;
              margin-top: 4px;
            }
          }
        }
      }

      .btn---cta {
        width: 100%;
        padding: 15px;
        background: whitesmoke;

        span {
          color: #0d44fb;
        }
      }
    }
  }

  //padding: 20px;
  //height: 106px;
  img {
    //padding: 0 60px;
    //margin-bottom: 3rem !important;
    height: 109px;
    margin-bottom: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.9rem 2.3rem;
  }

  .bmw_logo {
    height: 121px;
  }
}

.credit_card_container {
  width: 30em;

  .credit_card_item-1 {
    img {
      width: 70px
    }
  }

  .credit_card_item-2 {
    img {
      width: 100px;
      margin-top: -1px;
    }
  }

  .credit_card_item-3 {
    img {
      width: 45px;
      margin-top: -2px
    }
  }

  .credit_card_item-4 {
    img {
      width: 90px;
      margin-top: -10px;
    }
  }
}

.marketers {
  h5 {
    color: #1f216e;
    margin-top: 20px;
  }

  h2 {
    color: #1f216e;
    font-size: 2.1rem;
    margin-top: 100px
  }
}

.close_modal {
  float: right;
  margin-right: 20px;
  margin-top: 20px;
  font-size: x-large;
}
</style>
